<template>
  <v-main :class="desktop ? 'tway-background-curves-svg' : 'tway-background-curves-svg-mobile-nologo'">
    <v-container class="registration-container" fill-height>
      <v-row v-if="mobile" justify="center" class="pa-0 ma-0">
        <v-img class="my-5 mb-10" :src="require('@/assets/img/byline.svg')" max-width="250px" />
      </v-row>

      <v-card
        v-if="clientType == null"
        outlined
        width="70%"
        max-width="800px"
        min-width="350px"
        :class="desktop ? 'pa-4' : 'pa-4'"
        style="border-radius: 10px !important"
        class="registration-card"
      >
        <grid class-alone="pa-5 py-md-6 px-md-8 py-lg-10 px-lg-14">
          <template v-slot:alone>
            <div>
              <v-row v-if="clientType == null" justify="center" class="ma-0 pa-0 mb-4">
                <span
                  class="text-center"
                  :style="mobile ? 'font-size:24px; line-height:28px;' : 'font-size:40px; line-height: 50px;'"
                  >Selecciona el perfil que corresponde a tu empresa</span
                >
              </v-row>
              <div v-if="clientType == null">
                <grid
                  :class-left="mobile ? 'pa-0 mb-5 registration-form-border-bottom' : 'pl-0 py-0'"
                  :class-right="mobile ? 'pa-0 registration-form-border-bottom' : 'pr-0 py-0'"
                >
                  <template v-slot:left>
                    <v-card class="pa-8">
                      <div class="mb-4">
                        <v-img class="rounded" :src="require('@/assets/img/login/clientes.jpg')" contain width="100%" />
                      </div>
                      <div
                        style="color: #666666; font-weight: normal; font-size: 16px; line-height: 24px"
                        class="text-center roboto"
                      >
                        ¿Buscas servicios, productos o herramientas para la Transformación?
                      </div>

                      <div>
                        <v-btn
                          color="tway-violet"
                          class="button-text white--text mt-7"
                          rounded
                          @click="
                            () => {
                              clientType = 1;
                            }
                          "
                          width="100%"
                          height="36px"
                          id="im-client-button"
                        >
                          Soy Cliente
                        </v-btn>
                      </div>
                    </v-card>
                  </template>
                  <template v-slot:right>
                    <v-card class="pa-8">
                      <div class="mb-4">
                        <v-img
                          class="rounded"
                          :src="require('@/assets/img/login/especialistas.jpg')"
                          contain
                          width="100%"
                        />
                      </div>
                      <div
                        style="color: #666666; font-weight: normal; font-size: 16px; line-height: 24px"
                        class="text-center roboto"
                      >
                        ¿Ofreces soluciones, servicios o herramientas para la Transformación?
                      </div>
                      <div>
                        <v-btn
                          color="tway-violet"
                          class="button-text white--text mt-7"
                          rounded
                          @click="
                            () => {
                              clientType = 0;
                            }
                          "
                          width="100%"
                          height="36px"
                          id="im-specialist-button"
                        >
                          Soy Especialista
                        </v-btn>
                      </div>
                    </v-card>
                  </template>
                </grid>
                <v-row justify="center">
                  <v-btn
                    text
                    color="tway-violet"
                    class="pa-0 ma-0 roboto back align-end mt-8"
                    style="text-transform: initial"
                    :to="{ name: 'login' }"
                    id="register-go-back"
                  >
                    Volver al Inicio
                  </v-btn>
                </v-row>
              </div>
            </div>
          </template>
        </grid>
      </v-card>

      <v-card
        v-if="clientType != null"
        outlined
        max-width="700px"
        width="100%"
        :class="'complete-card mb-5 ' + (mobile ? '' : 'mt-10')"
      >
        <grid>
          <template v-slot:alone>
            <v-row class="ma-0 pa-0">
              <v-col :cols="mobile ? '12' : '8'">
                <div>
                  <div>
                    <v-col :class="mobile ? 'pa-0 mb-5' : 'pl-0 py-0'" class="ma-0" cols="12">
                      <div class="mb-4" :class="mobile ? 'text-center' : 'text-start'">
                        <strong>
                          <span style="font-size: 25px; line-height: 32px; color: #333333" v-if="clientType == 1"
                            >Soy Cliente</span
                          >
                          <span class="text-h4" v-else>Soy Especialista</span>
                        </strong>
                      </div>
                      <v-row v-if="mobile" class="mb-4 w-100" justify="center">
                        <v-img
                          class="rounded"
                          max-width="70%"
                          :src="
                            clientType == 1
                              ? require('@/assets/img/login/clientes-full.jpg')
                              : require('@/assets/img/login/especialistas-full.jpg')
                          "
                          contain
                        />
                      </v-row>
                      <div v-if="mobile" class="mb-5 text-center">
                        <span style="color: #999999 !important; font-family: Roboto Condensed !important">
                          <span class="text-h6" v-if="clientType == 1">Busco servicios</span>
                          <span class="text-h6" v-else>Ofrecemos Servicios</span>
                        </span>
                      </div>
                      <div class="mb-4 text-start">
                        <strong>
                          <span class="text-h6" v-if="clientType == 1">¿Qué puede hacer mi empresa en Tway?</span>
                          <span class="text-h6" v-else>¿Qué puede hacer mi empresa en Tway?</span>
                        </strong>
                      </div>
                      <div class="mb-5 text-start">
                        <span style="font-size: 16px; color: #666666 !important">
                          <span class="roboto" style="font-weight: 400 !important" v-if="clientType == 0">
                            <span>• Conocer su Índice de Madurez de Transformación Digital.</span>
                            <div class="divider my-2" />
                            <span>• Promocionar productos y servicios.</span>
                            <div class="divider my-2" />
                            <span>• Conectar con socios estratégicos.</span>
                            <div class="divider my-2" />
                            <span>• Concretar nuevos negocios en el Marketplace.</span>
                            <div class="divider my-2" />
                            <span>• Optimizar procesos de ventas.</span>
                            <div class="divider my-2" />
                          </span>
                          <span class="roboto" style="font-size: 16px; font-weight: 400 !important" v-else>
                            <span>• Conocer su Índice de Madurez de Transformación Digital.</span>
                            <div class="divider my-2" />
                            <span
                              >• Acceder a Especialistas, rankeados y validados que ofrecen soluciones a la medida para
                              mejorar la competitividad de tu negocio.</span
                            >
                            <div class="divider my-2" />
                            <span>• Medir el impacto de la Transformación Digital en tus indicadores de negocio.</span>
                            <div class="divider my-2" />
                            <span
                              >• Contar con acompañamiento del primer PMO Digital en Transformación Estratégica y mucho
                              más.</span
                            >
                            <div class="divider my-2" />
                          </span>
                        </span>
                      </div>
                      <v-row class="pa-0 mx-0 pt-0 mb-4 text-start" :justify="mobile ? 'center' : 'start'">
                        <a
                          style="width: 100%"
                          class="tway-violet--text text-decoration-underline"
                          @click="
                            () => {
                              clientType = null;
                            }
                          "
                        >
                          <v-row class="ma-0 pa-0" justify="center">
                            <v-col class="ma-0 pa-0" cols="2">
                              <v-row class="ma-0 pa-0 pr-2" justify="end">
                                <v-img :src="require('@/assets/img/arrow back.svg')" max-width="25px" />
                              </v-row>
                            </v-col>
                            <v-col class="ma-0 pa-0" cols="10">
                              <v-row class="ma-0 pa-0" justify="start">
                                <span class="roboto">Cambiar de perfil</span>
                              </v-row>
                            </v-col>
                          </v-row>
                        </a>
                      </v-row>
                    </v-col>
                  </div>
                </div>
              </v-col>
              <v-col class="pl-0" v-if="desktop" cols="4">
                <v-row class="ma-0 pt-15 pa-0" justify="center" align="center">
                  <v-img
                    class="rounded mb-4"
                    :src="
                      clientType == 1
                        ? require('@/assets/img/login/clientes-full.jpg')
                        : require('@/assets/img/login/especialistas-full.jpg')
                    "
                    contain
                  />
                  <div class="mb-5 text-center">
                    <span style="color: #999999 !important; font-family: Roboto Condensed !important">
                      <span class="text-h6" v-if="clientType == 1">Busco Servicios de Transformación Digital</span>
                      <span class="text-h6" v-else>Ofrecemos Servicios de Transformación Digital</span>
                    </span>
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </grid>
      </v-card>

      <v-row justify="center" class="pa-0 ma-0">
        <v-card
          v-if="clientType != null"
          outlined
          max-width="700px"
          width="100%"
          class="py-10"
          :class="'complete-card mb-5 ' + (mobile ? 'px-8' : 'mb-10 px-15')"
        >
          <grid :class-alone="'pa-0'">
            <template v-slot:alone>
              <v-form v-model="valid" ref="form">
                <v-row justify="center" class="w-100 mb-4">
                  <span v-if="desktop" style="font-size: 40px">Registra tu Empresa</span>
                  <span v-if="mobile" style="font-size: 24px">Registra tu Empresa</span>
                </v-row>
                <span>Datos Personales </span>
                <div class="mb-1 mt-4">
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="firstName"
                        label="Nombre"
                        name="name"
                        type="text"
                        :rules="[required('Nombre')]"
                        required
                        dense
                        outlined
                        color="#7319D5"
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="lastName"
                        label="Apellido"
                        name="lastName"
                        type="text"
                        :rules="[required('Apellido')]"
                        required
                        dense
                        outlined
                        color="#7319D5"
                      />
                    </v-col>
                  </v-row>
                </div>
                <v-row class="mb-3">
                  <v-col :cols="countryCode !== '' ? 6 : 12">
                    <v-autocomplete
                      v-model="countryCode"
                      label="País"
                      name="countryCode"
                      :items="availableCountries"
                      hide-no-data
                      hide-selected
                      item-text="name"
                      item-value="alpha2"
                      outlined
                      :rules="[required('País')]"
                      required
                      dense
                      color="#7319D5"
                    />
                  </v-col>
                  <v-col cols="6" v-if="countryCode !== ''"
                    ><v-autocomplete
                      v-model="metadata.regionCode"
                      label="Región"
                      name="Region"
                      :items="availableRegion"
                      hide-no-data
                      hide-selected
                      item-text="name"
                      item-value="code"
                      :rules="[required('Región')]"
                      required
                      dense
                      outlined
                      color="#7319D5"
                  /></v-col>
                </v-row>
                <div class="mb-4">
                  <grid class-alone="pa-0 mt-2">
                    <v-text-field
                      v-model="companyIdentifier"
                      v-if="countryCode !== ''"
                      :label="
                        countryCode === 'CL'
                          ? 'RUT Empresa'
                          : countryCode === 'CO'
                          ? 'NIT Empresa'
                          : countryCode === 'MX'
                          ? 'RFC Empresa'
                          : countryCode === 'PE'
                          ? 'RUC Empresa'
                          : ''
                      "
                      name="companyIdentifier"
                      type="text"
                      :rules="[required('Identificador tributario requerido'), tributaryValidations()]"
                      :hint="
                        countryCode === 'CL'
                          ? 'Formato: 12345678-9'
                          : countryCode === 'CO'
                          ? 'Formato: 1234567890-0'
                          : countryCode === 'MX'
                          ? 'Formato: XAXX010101000'
                          : countryCode === 'PE'
                          ? 'Formato: 12345678900'
                          : ''
                      "
                      required
                      dense
                      outlined
                      color="#7319D5"
                    />
                  </grid>
                </div>
                <div class="mb-4">
                  <v-text-field
                    v-model="email"
                    label="Tu Correo Corporativo"
                    name="email"
                    type="text"
                    :rules="[required('Correo Corporativo'), validateEmail()]"
                    required
                    dense
                    outlined
                    color="#7319D5"
                  />
                </div>
                <div class="mb-4">
                  <v-text-field
                    v-if="otherEntrySource"
                    v-model="entrySource"
                    label="¿Cómo te enteraste de nosotros? Selecciona una opción"
                    name="entrySource"
                    type="text"
                    :rules="[required('¿Cómo te enteraste de nosotros?')]"
                    required
                    dense
                    outlined
                    color="#7319D5"
                  />
                  <v-autocomplete
                    v-else
                    :disabled="referredEntry"
                    v-model="entrySource"
                    label="¿Cómo te enteraste de nosotros? Selecciona una opción"
                    name="entrySource"
                    :items="availableEntrySources"
                    hide-no-data
                    hide-selected
                    item-text="name"
                    item-value="key"
                    :rules="[required('¿Cómo te enteraste de nosotros?')]"
                    required
                    :change="changeSource(2)"
                    dense
                    outlined
                    color="#7319D5"
                  />
                </div>

                <v-row class="ma-0 pa-0 mb-4 register-column" justify="center">
                  <v-btn
                    color="tway-violet"
                    class="white--text"
                    height="36px"
                    width="50%"
                    rounded
                    :disabled="valid && clientType != null ? false : true"
                    @click="registerUser"
                    id="register-continue"
                  >
                    Continuar
                  </v-btn>
                </v-row>
                <v-row class="ma-0 pa-0" justify="center">
                  <router-link class="purple--text" :to="{ name: 'login' }"> ¿Ya tienes una cuenta? </router-link>
                </v-row>
              </v-form>
            </template>
          </grid>
        </v-card>
      </v-row>
    </v-container>
  </v-main>
</template>

<style lang="scss" scoped>
.register-column {
  span {
    font-size: 15px;
  }
}
.registration-card {
  max-width: 1100px;
}
.registration-container {
  display: flex;
  justify-content: center;
}
.divider {
  width: 100% !important;
}
.back {
  /* Link button RC 16 */

  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-decoration-line: underline;
}
</style>

<script>
import { mapActions } from "vuex";
import ValidatorsService from "@/services/validatorsService";
import companyService from "@/services/companyService";

import CountryCodes from "@/utils/CountryCodes";
import EntrySources from "@/utils/entrySources";
import RowTwo from "@/components/grid/RowTwo.vue";
function prepareEntrySources(data) {
  const other = data.filter((e) => e.key === "NONE");
  const tarjets = data.filter((e) => e.key !== "NONE").sort((a, b) => a.name.localeCompare(b.name));
  return [...tarjets, ...other];
}

export default {
  components: {
    grid: RowTwo,
  },
  data() {
    return {
      boss: "Catalina",
      businessName: "Symbiose",
      valid: false,
      availableCountries: CountryCodes,
      availableEntrySources: prepareEntrySources(EntrySources),
      availableBusinessArea: [],
      availableRegion: [],
      availableAgeRange: [],
      availableWorkplaceSeniority: [],
      firstName: "",
      lastName: "",
      countryCode: "",
      companyIdentifier: "",
      companyName: "",
      email: "",
      entrySource: "",
      otherEntrySource: false,
      referredEntry: false,
      clientType: null,
      businessParams: {},
      promo: null,
      metadata: {
        regionCode: {},
      },
      token: null,
    };
  },

  created() {
    if (this.$recaptchaInstance) {
      this.$recaptchaInstance.showBadge();
    }
    localStorage.removeItem("accessToken");
  },

  async mounted() {
    let urlSource = this.$route.query.source;
    this.promo = this.$route.query.promo;
    this.businessParams = { ...this.businessParams, promo: this.promo };
    if (this.promo) {
      localStorage.setItem("promo", true);
    }
    if (urlSource && this.availableEntrySources.find((es) => es.key === urlSource)) {
      this.entrySource = urlSource;
      this.referredEntry = true;
    }
  },

  beforeRouteLeave(to, from, next) {
    this.$recaptchaInstance.hideBadge();
    next();
  },

  methods: {
    async fillRegionAutocomplete(countryId) {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("registration");
      const data = await companyService.getCompanyRegion(countryId, token);
      this.availableRegion = data;
    },
    required: (propertyType) => ValidatorsService.required(propertyType),
    validateEmail: () => ValidatorsService.validateEmail(),
    tributaryValidations() {
      if (this.countryCode === "CL") {
        return ValidatorsService.validateRUT();
      }
      if (this.countryCode === "MX") {
        return ValidatorsService.validateRFC();
      }
      if (this.countryCode === "CO") {
        return ValidatorsService.validateNIT();
      }
      if (this.countryCode === "PE") {
        return ValidatorsService.validateRUC();
      }
      return true;
    },
    changeSource(num) {
      if (num === 2) {
        this.otherEntrySource = this.entrySource === "NONE";
        if (this.otherEntrySource) {
          this.entrySource = "";
        }
      } else {
        return this.workArea;
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$router.push({ name: "activation" });
      }
    },
    ...mapActions("authentication", ["doRegister", "doLogout"]),

    async registerUser() {
      if (this.clientType == null) return;
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("registration");
      let data = {
        firstName: this.firstName,
        lastName: this.lastName,
        companyName: this.companyName,
        companyIdentifier: this.companyIdentifier,
        email: this.email,
        clientType: this.clientType === 0 ? "SPECIALIST" : "CLIENT",
        countryCode: this.countryCode,
        source: this.entrySource,
        token: token,
        businessParams: this.businessParams,
        metadata: this.metadata,
      };
      this.doRegister(data)
        .then(() => {
          this.$router.push({
            name: "confirmation",
            params: { email: this.email, clientType: this.clientType },
          });
        })
        .catch((err) => this.$log.error(err));
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
    rutLabel() {
      let label = "Escribe el Identificador tributario de tu empresa";
      if (this.countryCode === "CL") {
        label = "Escribe el Identificador tributario de tu empresa";
      }
      return label;
    },
  },
  watch: {
    countryCode() {
      let data = this.availableCountries.find((element) => element.alpha2 == this.countryCode);
      this.fillRegionAutocomplete(data.countryCode);
    },
  },
};
</script>
